<template>
  <!-- 内推人注册页面 -->
  <div class="referRegister" :style="{ minHeight: windowHeight }">
    <div class="container">
      <div class="title">感谢您参与CSON全球IT内推人领航计划</div>
      <div class="subtitle">
        非常感谢您参与CSON全球IT内推人领航计划，通过CSON官方平台为每一位求职者提供宝贵的内推职位机会
        <br />
        为了确保后续给您提供的求职者信息，符合您的要求，请详细完善以下信息
      </div>
      <div class="form">
        <template v-if="!isAccess">
          <div class="tips">
            为了保证内推真实有效，每位内推人均需要进行信息认证，认证信息完全不展示在前台页面
          </div>
          <el-form :model="form" label-width="120px" :rules="rules" ref="form">
            <el-form-item label="nickName" prop="nickName">
              <el-input v-model="form.nickName"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="skype用户名" prop="skypeUserName">
              <el-input v-model="form.skypeUserName"></el-input>
            </el-form-item>
            <el-form-item label="skype邮箱" prop="skypeEmail">
              <el-input v-model="form.skypeEmail"></el-input>
            </el-form-item>
            <el-form-item label="当前公司" prop="company">
              <el-autocomplete
                v-model="searchCompanyKey"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                placeholder="输入公司名"
                :popper-append-to-body="false"
                @input="searchLodash"
                @select="selectCompany"
                @keydown="form.companyId = ''"
                @keydown.native.enter="selectDefaultCompany"
                style="width: 100%"
              >
                <template slot-scope="{ item }" v-if="companys.length > 0">
                  {{ item.name }}
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="本人手持工牌照" prop="workCard">
              <el-upload
                ref="upload"
                :on-success="handleSuccess"
                :limit="1"
                :before-upload="beforeUpload"
                :on-remove="removeFile"
                :action="uploadUrl"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                style="display: inline-block; width: 100%"
                :data="{ isPublic: false }"
                :headers="{ 'X-Access-Token': token }"
                :show-file-list="false"
              >
                <el-button icon="el-icon-paperclip" size="small" slot="trigger"
                  >上传照片
                </el-button>
                <span
                  style="
                    vertical-align: top;
                    margin-left: 12px;
                    cursor: pointer;
                  "
                  @click="showViewer = true"
                >
                  <i class="el-icon-info"></i>
                  查看范例</span
                >
                <img
                  style="width: 100%"
                  v-if="cover && cover != ''"
                  :src="cover"
                  alt=""
                />
              </el-upload>
              <el-image-viewer
                v-if="showViewer"
                :z-index="9999"
                :url-list="[require('@/assets/refer/example.png')]"
                :on-close="closeViewer"
              />
            </el-form-item>
            <div style="text-align: center">
              <el-button type="primary" @click="submitForm">提交资料</el-button>
            </div>
          </el-form>
        </template>
        <template v-else>
          <div class="success">
            <img class="successIcon" src="@/assets/img/sh-success.png" alt="" />
            <div class="title-s">提交成功！</div>
            <div class="subtitle-s">
              认证资料将在<span style="color: rgba(0, 117, 246, 1)"
                >24小时内</span
              >审核完成
            </div>
            <el-button type="primary" @click="returnTo">回到首页</el-button>
          </div>
        </template>
      </div>
    </div>
	<leetDialog></leetDialog>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getMockInterviewer, saveMockInterviewer } from "@/service/mock";
import leetDialog from "@/components/leetDialog.vue";
import _ from "lodash";
import { searchCompanys } from "@/service/company";
export default {
  name: "referRegister",
  components: { ElImageViewer ,leetDialog},

  data() {
    const companyValidator = (rule, value, callback) => {
      if (this.form.companyId == "") {
        callback(new Error("请填写公司!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        nickName: "",
        email: "",
        skypeUserName: "",
        skypeEmail: "",
        companyId: "",
      },
      cover: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      isAccess: false,
      showViewer: false,
      companys: [],
      timeout: null,
      searchCompanyKey: "",
      rules: {
        nickName: [
          {
            required: true,
            message: "请输入nickName",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        skypeUserName: [
          {
            required: true,
            message: "请输入skype用户名",
            trigger: "blur",
          },
        ],
        skypeEmail: [
          {
            required: true,
            message: "请输入skype邮箱",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            validator: companyValidator,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    windowHeight() {
      return window.innerHeight + "px";
    },
  },
  mounted() {
    this.getMockRegisterDetail();
  },
  methods: {
    handleSuccess(res, file) {
      this.cover = URL.createObjectURL(file.raw);
      this.form.workCard = res.result.objectKey;
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    removeFile() {
      this.form.workCard = "";
      this.cover = "";
    },
    returnTo() {
      this.$router.push("/");
    },
    closeViewer() {
      this.showViewer = false;
    },
    getMockRegisterDetail() {
      getMockInterviewer().then((res) => {
        if (res.success) {
          this.form.nickName = res.result.nickName ? res.result.nickName : "";
          this.form.email = res.result.email ? res.result.email : "";
          this.form.skypeUserName = res.result.skypeUserName
            ? res.result.skypeUserName
            : "";
          this.form.skypeEmail = res.result.skypeEmail
            ? res.result.skypeEmail
            : "";
          this.form.companyId = res.result.companyId
            ? res.result.companyId
            : "";
          this.searchCompanyKey = res.result.companyName
            ? res.result.companyName
            : "";
          this.cover = res.result.workCardAll ? res.result.workCardAll : "";
          this.workCard = res.result.workCard ? res.result.workCard : "";
        }
      });
    },
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function (val) {
      this.searchCompanys(val);
    }, 1000),
    searchCompanys(val) {
      searchCompanys(val).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.form.companyId = option.id;
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.form.companyId = this.companys[0].id;
      } else {
        this.form.companyId = "";
      }
    },
    deleteCompany(index) {
      this.form.originalCompany.splice(index, 1);
    },

    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveMockInterviewer(this.form).then((res) => {
            if (res.success) {
              this.isAccess = true;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.referRegister {
  width: 100%;
  background: url(../../assets/mock/register.png) no-repeat;
  background-size: 100% 100%;
  .container {
    width: 880px;
    margin: 0 auto;
    padding-bottom: 80px;
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 1px;
      text-align: center;
      padding-top: 108px;
      color: #fff;
    }
    .subtitle {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #fff;
      line-height: 24px;
      text-align: center;
      margin-top: 16px;
    }
    .form {
      margin-top: 40px;
      padding: 50px 160px;
      border-radius: 8px;
      background: #fff;
      .tips {
        margin-bottom: 40px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
      }
      .relativeInput {
        position: relative;
        margin-bottom: 6px;
      }
      .absoluteIcon {
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        cursor: pointer;
      }
    }
    .success {
      padding: 120px 0;
      text-align: center;
      .successIcon {
        width: 40px;
        height: 40px;
      }
      .title-s {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 24px;
        margin-top: 30px;
      }
      .subtitle-s {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #34495e;
        line-height: 21px;
        margin-top: 14px;
        margin-bottom: 48px;
      }
    }
  }
}
</style>